  /* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "ap-southeast-2",
  "aws_cloud_logic_custom": [
      {
          "name": "AdminQueries",
          "endpoint": "https://uk5p4ofji7.execute-api.ap-southeast-2.amazonaws.com/lendlease",
          "region": "ap-southeast-2"
      }
  ],
  "aws_appsync_graphqlEndpoint": "https://xyr44qmdcbau3fzieqaqannele.appsync-api.ap-southeast-2.amazonaws.com/graphql",
  "aws_appsync_region": "ap-southeast-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_cognito_identity_pool_id": "ap-southeast-2:ae5e8263-bbd7-4ecd-ae5a-06fad6878f53",
  "aws_cognito_region": "ap-southeast-2",
  "aws_user_pools_id": "ap-southeast-2_Rv0891tBn",
  "aws_user_pools_web_client_id": "3v5f84j8iloq9f5qbhmqhnqaaj",
  "oauth": {},
  "aws_cognito_username_attributes": [
      "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL",
      "FAMILY_NAME",
      "GIVEN_NAME",
      "PHONE_NUMBER"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
      "SMS"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ],
  "aws_user_files_s3_bucket": "intellitrackstorage70714-lendlease",
  "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
